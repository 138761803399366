import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import vision from "../assets/Culture/culture-vision.svg";
import philosophy from "../assets/Culture/culture-philosophy.svg";
import team from "../assets/Culture/culture-team.svg";
import developingV2 from "../assets/Culture/culture-developing-v2.svg";
import choices from "../assets/Culture/culture-icon-choices.svg";
import skills from "../assets/Culture/culture-icon-skills.svg";
import decisions from "../assets/Culture/culture-icon-decisions.svg";
import communication from "../assets/Culture/culture-icon-communication.svg";
import noRules from "../assets/Culture/culture-icon-no-rules.svg";
import transforming from "../assets/Culture/culture-icon-transforming.svg";
import quoteIcon from "../assets/Culture/icon-quote.svg";
import quoteImage from "../assets/Culture/testimonial-1.jpg";
import AboutGrid from "../components/About/aboutGrid";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as styles from "./culture.module.less";

const Title = styled.div`
  font-size: ${({ size }) => (size ? `${size}` : "30px")};
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}` : "unset"};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : "unset")};
`;

const Gradient = styled.div`
  width: 100%;
  background-image: linear-gradient(to top, #f8fcff, #fff);
  padding: 0 10%;
  @media screen and (max-width: 450px) {
    padding: 0 20px;
  }
`;

const Text = styled.div`
  font-size: ${({ size }) => (size ? `${size}` : "16px")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}` : 1.88)};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? `${letterSpacing}` : "0.02px"};
  color: ${({ colour }) => (colour ? `${colour}` : "#363f41")};
  white-space: pre-line;
  width: ${({ maxWidth }) => (maxWidth ? "824px" : "unset")};
  @media screen and (max-width: 770px) {
    width: unset;
  }
`;

const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 10%;
  margin-left: 10%;
`;

const GridDiv = styled.div`
  display: grid;
  justify-content: space-around;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 36px;
  grid-column-gap: 80px;
  margin-right: 5%;
  margin-left: 5%;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 80px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 220px;
  justify-self: center;
  align-self: center;
  text-align: center;
`;

const QuoteBox = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 10px;
  box-shadow: 0 7px 19px 0 rgba(36, 45, 65, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

const Photo = styled.img`
  width: 100%;
`;

const QuoteIcon = styled.img`
  width: 61px;
  height: 61px;
  margin-left: calc(50% - 30px);
  margin-top: -30px;
`;

const TextDiv = styled.div`
  height: 50%;
  padding: 26px 22px 0 28px;
`;

const TextWrapper = styled.div`
  margin-top: 28px;
  padding-bottom: 80px;
  margin-left: 50px;
  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
`;

const GlassdoorImage = styled.img`
  width: 160px;
  padding-top: 20px;
`;

const CardImage = styled.img`
  width: 80px;
  margin-bottom: 12px;
`;

const card1 = {
  img: choices,
  text: "Make the right choices and do what is right for the success of the company",
};

const card2 = {
  img: skills,
  text: "Develop your own skills and those of the people around you",
};

const card3 = {
  img: decisions,
  text: "Feel empowered to make decisions",
};

const card4 = {
  img: communication,
  text: "Communicate openly and honestly across the company",
};

const card5 = {
  img: noRules,
  text: "Are not limited by unnecessary rules",
};

const card6 = {
  img: transforming,
  text: "Have fun whilst knowing you are transforming an industry",
};

const Culture = (props) => {
  const cardArray = [card1, card2, card3, card4, card5, card6];

  const valuesGrid = () => {
    return cardArray.map((card, index) => {
      return (
        <CardWrapper>
          <CardImage src={card.img} alt={`card${index}`} />
          <Text colour="rgba(36, 45, 65, 0.9)" lineHeight="1.5">
            {card.text}
          </Text>
        </CardWrapper>
      );
    });
  };

  return (
    <Layout>
      <Seo
        title="Our Culture"
        description="We are creating aviation for the modern world helping our customers excel in safety and efficiency. By transforming the way our customers work, we are enabling them to focus on the insights and data that really matter. We have an exciting horizon where the skies really are the limit!"
      />
      <PageHeader
        header="Our Culture"
        subHeader="We are creating aviation for the modern world helping our customers excel in safety and efficiency. By transforming the way our customers work, we are enabling them to focus on the insights and data that really matter. We have an exciting horizon where the skies really are the limit!"
      />
      <Container>
        <Row className={`justify-content-md-center ${styles.headerSpace}`}>
          <Col md={12} lg={7}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
              delay={150}
            >
              <Title size="30px" marginBottom="20px">
                TrustFlight Vision
              </Title>
              <Text>{`Our vision is to create a  community that works together to leverage the latent potential of aviation data, improve safety and efficiency for the benefit of all users of aviation. Our people are part of that community and will help shape the future.

                Like any growing, successful company we want to attract, develop and keep the most talented people to help us fulfil our vision.`}</Text>
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={5}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
              delay={150}
            >
              <img src={vision} alt="vision" />
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <Gradient>
        <AnimationOnScroll
          animateOnce
          animateIn="animate__fadeIn"
          offset={0}
          delay={150}
        >
          <CenteredDiv>
            <Title size="32px" marginBottom="30px" marginTop="60px">
              Our Values
            </Title>
            <Text maxWidth>
              Our values reflect the nature of our sector and our ambitions in
              transforming the aviation industry; we value integrity, encourage
              responsibility, require leadership at all levels, seek innovation
              and demand excellence. To do this we feel we have created a
              special place to work. Working here will mean you:
            </Text>
          </CenteredDiv>
          <SpaceBetweenDiv>
            <GridDiv>{valuesGrid()}</GridDiv>
          </SpaceBetweenDiv>
        </AnimationOnScroll>
      </Gradient>
      <Container style={{ paddingBottom: "20px" }}>
        <Row className={`justify-content-md-center ${styles.headerSpace}`}>
          <Col md={12} lg={7}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
              delay={150}
            >
              <Title size="30px" marginBottom="20px">
                Our Philosophy
              </Title>
              <Text>{`We are moving fast and want you to feel that you are keeping up, that’s why we have tried to minimise rules and create a learning environment. What this means in practice is that where possible (i.e. it doesn’t breach safety standards or regulations) you do what is right, you use your sound judgement and knowledge in your role to make decisions, change processes and offer different solutions. If it adds value to the business and helps others nothing can be better.

                We will always look to the subject experts to help us make decisions about the way forward and what next, after all who better to ask than the people doing the role every day.`}</Text>
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={5}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
              delay={150}
            >
              <img src={philosophy} alt="philosophy" />
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <AboutGrid culture />
      <Container>
        <Row className={`justify-content-md-center ${styles.headerSpace}`}>
          <Col md={12} lg={7}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
              delay={150}
            >
              <Title size="30px" marginBottom="20px">
                Our Team
              </Title>
              <Text>{`We have a ‘one team’ approach here and that isn’t just words!  We are all responsible for the success of TrustFlight.  Collaborating together, giving frequent and constructive feedback and demonstrating trust are the fundamentals of our leadership model.  It doesn’t matter what team you sit within or where you see yourself in the hierarchy, that’s all just there to help us manage the day-to-day business - if you see something you think is not right, needs changing or could definitely be done better you go and talk to the ‘owner’ of that ‘thing’ and agree on how to make a change.`}</Text>
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={5} className={styles.mobileImagePadding}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
              delay={150}
            >
              <img src={team} alt="team" />
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <Gradient>
        <Row
          className={`justify-content-md-center ${styles.headerSpace}`}
          style={{ paddingBottom: "80px" }}
        >
          <Col
            md={12}
            lg={5}
            className={`${styles.nonMobileImage} ${styles.mobileImagePadding}`}
          >
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
              delay={150}
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "100%",
              }}
            >
              <img src={developingV2} alt="developing" />
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={7}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
              delay={150}
            >
              <Title size="30px" marginBottom="20px">
                We're Developing
              </Title>
              <Text>{`Like our company, products and our people we are developing, we are not the finished article.  Some may find our approach challenging or unstructured, this is to be expected - sometimes we are struggling to meet our own ambitions - but with the team we have today and the team we will become tomorrow we know that we are on the right path to make an enormous difference to aviation and to our own skills and abilities.  There may be a little turbulence along the way but as we look back on what we have achieved and how far we have come you will be able to see that you really did make a difference - to TrustFlight, to yourself and the wider industry.`}</Text>
            </AnimationOnScroll>
          </Col>
          <Col
            md={12}
            lg={5}
            className={`${styles.mobileImage} ${styles.mobileImagePadding}`}
          >
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
              delay={150}
            >
              <img src={developingV2} alt="developing" />
            </AnimationOnScroll>
          </Col>
        </Row>
      </Gradient>
      <Container>
        <CenteredDiv>
          <Title size="32px" marginTop="100px" marginBottom="92px">
            Don't just take our word for it, hear from the team...
          </Title>
        </CenteredDiv>
      </Container>
      <Gradient>
        <Row className={styles.quoteRow}>
          <Col md={5} style={{ paddingLeft: 0 }}>
            <QuoteBox>
              <Photo src={quoteImage} alt="quote image" />
              <QuoteIcon src={quoteIcon} alt="quote icon" />
              <TextDiv>
                <Text lineHeight="1.5">
                  Along with growing professionally, I am excited about the
                  variety of learning opportunities at TrustFlight. Being
                  surrounded by intrinsically motivated colleagues positively
                  influences my workday. I have been lucky with a team that
                  values user research and is excited to see how it shapes the
                  future of TrustFlight’s suite of products.
                </Text>
                <Title size="16px" marginTop="20px" marginBottom="38px">
                  Binoodha, Senior UX Researcher
                </Title>
              </TextDiv>
            </QuoteBox>
          </Col>
          <Col md={7}>
            <TextWrapper>
              <Title size="20px" marginBottom="32px">
                What it's like working here
              </Title>
              <Title size="14px" marginBottom="12px">
                What do you do in your everyday job that makes a difference to
                the company (or the industry)?
              </Title>
              <Text size="14px" lineHeight="22px" letterSpacing="0">
                I have reflected on what value I provide to a company like
                TrustFlight that hosts a powerhouse of talents and industry
                experts. My main goal is to place our users at the core of our
                design process and products. I also like to imagine my role as a
                facilitator to the team’s learning process. I focus on
                conducting collaborative user research and iteratively improving
                the user experience of TF's products by observing user
                behaviours.
              </Text>
              <Title size="14px" marginTop="28px" marginBottom="12px">
                What do you enjoy about working at TrustFlight?
              </Title>
              <Text size="14px" lineHeight="22px" letterSpacing="0">
                I could say a lot, but the biggest highlight of working at TF is
                being surrounded by people who are truly passionate about the
                aviation industry. It's made me change the way I perceived my
                whole flying experience. I also enjoy working with a global team
                where different cultures shape creativity. I get to learn all
                the time, every day.{" "}
              </Text>
              <Title size="14px" marginTop="28px" marginBottom="12px">
                What challenges are you working to overcome to make things
                better at TF?
              </Title>
              <Text size="14px" lineHeight="22px" letterSpacing="0">
                I'd say it's always easier to make things complex. But our
                biggest challenge has been in understanding how to make complex
                products simple. We are working on doing things better than
                doing things faster. I am doing this by first challenging
                assumptions that drive product development and setting learning
                goals with the team to understand our users’ workflow better.{" "}
              </Text>
              <a
                aria-label="glassdoor"
                href="https://www.glassdoor.co.uk/Overview/Working-at-TrustFlight-EI_IE2962748.11,22.htm"
              >
                <GlassdoorImage src="https://www.glassdoor.co.uk/api/widget/verticalStarRating.htm?e=2962748" />
              </a>
            </TextWrapper>
          </Col>
        </Row>
      </Gradient>
      <Started
        title="Join the Team"
        text="Join the TrustFlight team and help us reshape the aviation industry!"
        buttonText="Looking for a new challenge?"
        buttonTextStrong="View available positions"
        link="/careers/"
      />
    </Layout>
  );
};
export default Culture;
